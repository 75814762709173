import React, { FunctionComponent } from 'react';
import { ToolResultProps } from '../../../types';
import {TECH_STACK_SUBSCRIPTION} from "../../../graphql/subscriptions";
import FullResultCard from "./full-result";
import BaseToolWrap from "../BaseToolWrap";

const TechStackTool: FunctionComponent<ToolResultProps> = ({ url, sessionToken, fullResult, onResult, simpleResult, handleScanLimitCheck, ...rest }) => (
  <BaseToolWrap
    query={TECH_STACK_SUBSCRIPTION}
    FullResult={FullResultCard}
    SimpleResult={FullResultCard}
    url={url}
    sessionToken={sessionToken}
    fullResult={fullResult}
    onResult={onResult}
    simpleResult={simpleResult}
    {...rest}
    handleScanLimitCheck={handleScanLimitCheck}
  />
);

export  default TechStackTool;
