import React, {FC, useState} from 'react';
import {Link, navigate} from 'gatsby';

import Layout from '../../components/layout/layout';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import {Translate} from '../../components/translations';
import {DomainForm} from '../../components/form/domain-form';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import ToolToken from '../../components/scan-tools/ToolToken';
import AssetCheckerTool from '../../components/scan-tools/assetsCheck';
import DomainWhoisTool from '../../components/scan-tools/domain-whois';
import DeviceScreenshotsTool from '../../components/scan-tools/device-screenshots';
import TechStackTool from '../../components/scan-tools/tech-stack';
import SSLExtractorTool from '../../components/scan-tools/sslExtractor';
import WebMetricsTool from '../../components/scan-tools/webMetricsTool';
import PageAnalayzerTool from '../../components/scan-tools/page-analyzer';
import Button from '../../components/button';
import {Modal} from '../../components/modal';

import {MAX_NUMBER_OF_SCANS} from '../../helpers/constants';
import {ScanResultsPageProps} from '../../types';

import './styles.scss';

const SimpleScanPage: FC<ScanResultsPageProps> = props => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const hasDomain = url && url.length > 0;
  const encodedURL = encodeURIComponent(url);

  return (
    <Layout>
      <PageHero
        title={<Translate name={'RESULTS_PAGE_SUBTITLE'} />}
        // subtitle={""}
      >
        <DomainForm value={url} onSubmit={u => navigate(`/tools/${encodeURIComponent(u)}`)} />
      </PageHero>
      <Section>
        <div className="col-md-12">
          {hasDomain ? (
            <ScanCountChecker url={url}>
              <ToolToken url={url || ''} scanCounterValue={MAX_NUMBER_OF_SCANS}>
                {toolsSessionToken => (
                  <div className="row">
                    <div className="col-md-8 offset-md-2 offset-xl-2 offset-lg-2">
                      <DeviceScreenshotsTool
                        simpleResult
                        url={url}
                        sessionToken={toolsSessionToken}
                        handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="card-columns">
                        <div className="card border-0">
                          <DomainWhoisTool
                            simpleResult
                            url={url}
                            sessionToken={toolsSessionToken}
                            moreButton={
                              <a href={`/domain-whois/${encodedURL}`} target="_blank" className="float-right">
                                <Button className="btn-sm" light>
                                  Learn More
                                </Button>
                              </a>
                            }
                            handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                          />
                        </div>
                        <div className="card border-0">
                          <AssetCheckerTool
                            simpleResult
                            url={url}
                            sessionToken={toolsSessionToken}
                            moreButton={
                              <a href={`/page-analyzer/${encodedURL}`} target="_blank" className="float-right">
                                <Button className="btn-sm" light>
                                  Learn More
                                </Button>
                              </a>
                            }
                            handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                          />
                        </div>
                        <div className="card border-0">
                          <SSLExtractorTool
                            simpleResult
                            url={url}
                            sessionToken={toolsSessionToken}
                            moreButton={
                              <a
                                href={`/ssl-certificate-checker/${encodedURL}`}
                                target="_blank"
                                className="float-right"
                              >
                                <Button className="btn-sm" light>
                                  Learn More
                                </Button>
                              </a>
                            }
                            handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                          />
                        </div>
                        <div className="card border-0">
                          <WebMetricsTool
                            simpleResult
                            url={url}
                            sessionToken={toolsSessionToken}
                            moreButton={
                              <a href={`/page-speed-scanner/${encodedURL}`} target="_blank" className="float-right">
                                <Button className="btn-sm" light>
                                  Learn More
                                </Button>
                              </a>
                            }
                            handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                          />
                        </div>
                        <div className="card border-0">
                          <PageAnalayzerTool
                            simpleResult
                            url={url}
                            sessionToken={toolsSessionToken}
                            moreButton={
                              <a href={`/broken-links/${encodedURL}`} target="_blank" className="float-right">
                                <Button className="btn-sm" light>
                                  Learn More
                                </Button>
                              </a>
                            }
                            handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                          />
                        </div>
                        <div className="card border-0">
                          <TechStackTool
                            simpleResult
                            url={url}
                            sessionToken={toolsSessionToken}
                            moreButton={
                              <a href={`/stack-checker/${encodedURL}`} target="_blank" className="float-right">
                                <Button className="btn-sm" light>
                                  Learn More
                                </Button>
                              </a>
                            }
                            handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                          />
                        </div>
                      </div>
                    </div>
                    {scanLimitReached && (
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                          <p>&nbsp;</p>
                          <Modal
                            isOpen={true}
                            modalToggle={() => {}}
                            title="LOGIN_TO_CONTINUE"
                            modalFooter={
                              <Link to="/#login" state={{goBack: true}}>
                                <button type="button" className="btn btn-secondary">
                                  <Translate name="GO_TO_LOGIN_PAGE" />
                                </button>
                              </Link>
                            }
                          >
                            <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                          </Modal>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </ToolToken>
            </ScanCountChecker>
          ) : (
            <p>
              <Translate name="TOOLS_SIMPLE_SCAN_TEXT_WITHOUT_URL" />
            </p>
          )}
        </div>
      </Section>
    </Layout>
  );
};

export default SimpleScanPage;
