import React, { Fragment } from "react";
import { Link } from 'gatsby';
import { ResultCard } from "../../result-card";
import { TechStackQueryResultProps } from "../../../types";
import { Translate } from "../../translations";
import { cleanURLSpace } from "../../../helpers";
import { ProgressBar } from "../../progressBar";
import banner from '../../../img/tech-stack-tool-banner.jpg';

export default ({ url, applications, simpleResult, moreButton, _error }: TechStackQueryResultProps) => (
  <Fragment>
    <div className="row">
      {!url && !_error && (
        <div className="col-md-12 text-center">
          <div className="text-center">
          <ProgressBar />
          </div>
        </div>
      )}
    </div>
    {url && (
      <div className="row">
        <div className="col-md-12">
          {applications && !!applications.length ? (
            <ResultCard
              title={(
                <>
                  {moreButton}
                  <Translate name="TECH_STACK_RESULT" />
                </>
              )}
              items={[
                {
                  description: (
                    <div className="row">
                      {applications &&
                        applications.map(app => (
                          <div
                            key={app.name}
                            className="col-md-4 text-center mb-4"
                          >
                            <p>
                              <img
                                alt={app.name}
                                style={{ margin: 5 }}
                                src={`/tech-stack-icons/${encodeURIComponent(
                                  app.icon
                                )}`}
                                width={simpleResult ? 20 : 30}
                              />
                            </p>
                            {simpleResult ? (
                              <h6>{app.name}</h6>
                            ) : (
                              <h5>{app.name}</h5>
                            )}
                            <p>
                              <Link
                                to={`/most-popular-tech-stacks/${cleanURLSpace(
                                  app.categories[0]
                                )}`}
                              >
                                {app.categories[0]}
                              </Link>
                            </p>
                          </div>
                        ))}
                      <div className="col-md-4"></div>
                    </div>
                  ),
                },
              ]}
            />
          ) : !simpleResult && (
            <Translate name="NO_RESULTS_TECH_STACK" />
          )}
        </div>
        <div className='col-md-12 mt-4'>
          <img 
            src={banner}
            alt='Technology Monitoring'
            width={900}
            onClick={() => window.open('https://hexowatch.com/?utm_source=hexometer&utm_medium=banner&utm_campaign=free-tool-banner', '_blank')}
            style={{cursor: 'pointer', maxWidth: '900px', width: '100%'}}
          />
        </div>
      </div>
    )}
  </Fragment>
);
